import React from "react";

import './SearchTagItem.css';

const SearchTagItem = props => {
    return(
        <li className="quickfilter-item" onClick={() => props.searchTagCloseHandler(props.prod_id)}>
            <button>
                {props.data.title}
                <svg width="14" height="13" viewBox="0 0 14 13" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <rect width="12.6923" height="12.6923" transform="translate(0.561523 0.144531)" fill="white" fillOpacity="0.01" style={{"mixBlendMode":"multiply"}}/>
                    <path d="M10.0815 3.8717L9.52623 3.31641L6.90844 5.9342L4.29064 3.31641L3.73535 3.8717L6.35315 6.48949L3.73535 9.10729L4.29064 9.66258L6.90844 7.04478L9.52623 9.66258L10.0815 9.10729L7.46373 6.48949L10.0815 3.8717Z" fill="#161616"/>
                </svg>
            </button>
        </li>
    )
}

export default SearchTagItem;