import React from "react";
import { Link } from 'react-router-dom';

import './ProductCard.css';

import capabilityJSON from '../../Data/capabilities.json';

const ProductCard = ({ product, capability }) => {

    const deploymentMap = {
        'On-premises': 'Software',
        'SaaS': 'SaaS'
    }

    const generateCapabilityTags = () => {
        const tags = [];
        capability.forEach(cid => {
            tags.push(<div key={`capability-tag-${product.title}-${cid}`} className="product-card-tags-item">{capabilityJSON[cid].title}</div>)
        });
        return tags;
    }

    return (
        <div className="product-card"
            update-date="2021-09-23 16:39:21"
            data-tag={`${capability.title} ${product.deployment}`}
        >
            <h4>{product.title}</h4>
            <div className="product-card-tags">
                {
                    product.deployment ? <div className="product-card-tags-item">{deploymentMap[product.deployment]}</div> : ''
                }
                {
                    [...generateCapabilityTags()]                   
                }
                {/* <div className="product-card-tags-item update-tag">{product.updated}</div> */}
            </div>
            <Link to={`${product['product_card_link']}`} 
                className="product-card-link" 
                title={`Click here to view ${product.title}`}
                rel="noopener noreferrer"
                target={product['product_card_link'].match(/\.(txt|html|gif|pdf|doc|docx|xls|xlsx|js|htm)/g)?'_blank':'_self'}
            ></Link>
        </div>
    )
}

export default ProductCard;
