import React from 'react';
import './404.css';

import NavBar from "../../Components/NavBar/NavBar";
import Feedback from "../../Components/Feedback/Feedback";
import Footer from "../../Components/Footer/Footer";
import EmptyStateImage from '../../assets/images/404.svg';

const NotFoundPage = () => {
  const baseUrl = window.location.origin;

  return (
    <div className="not-found-page">
      <NavBar />
      <div className="content">
        <div className="text-container">
          <p className="title">404</p>
          <p className="subtitle">Page Not Found</p>
          <div className="call-to-action">
            <p className="instructions">Check the URL or try the following:</p>
            <div className="cta-buttons">
              <button className="feedback-btn" onClick={() => {
                const feedbackIcon = document.querySelector('.feedback');
                // console.log(feedbackIcon);
                if (feedbackIcon) {
                  feedbackIcon.click();
                }
              }}>Share your feedback</button>
              <button className="search-btn" onClick={() => {
                const path = window.location.pathname.substring(1).replace(/\//g, '+');
                window.open(`${baseUrl}/search?q=${path}`, '_blank');
              }}>Search for more</button>
            </div>
            <button className="home-btn" onClick={() => window.location.href = baseUrl}>Home</button>
          </div>
        </div>
        <div className="image-container">
          <img src={EmptyStateImage} alt="404 Not Found" />
        </div>
      </div>
      <Feedback />
      <Footer />
    </div>
  );
};

export default NotFoundPage;
