import React, { useState } from 'react';
import { createBrowserRouter, RouterProvider, redirect } from 'react-router-dom';

import './App.css';
import LandingPage from './Pages/LandingPage/LandingPage';
import ProductLandingPage from './Pages/ProductLandingPage/ProductLandingPage';
import SearchPage from './Pages/SearchPage/SearchPage';
import NotFoundPage from './Pages/404/404'; 

import productJSON from './Data/products.json';

function App() {

  const [selectedCapability, setSelectedCapability] = useState('');

  const router = createBrowserRouter([
    {
      path: '/',
      element: <LandingPage selectedCapability={selectedCapability} setSelectedCapability={setSelectedCapability} />,
      loader: ({ request }) => {
        const url = new URL(request.url);
        const q = url.searchParams.get('q');
        if (q === '404') {
          return redirect('/404');
        }
        return null;
      },
    },
    {
      path: '/search',
      element: <SearchPage />,
    },
    {
      path: '/on-premises/webmethods-adapter',
      loader: () => redirect('/on-premises/webmethods-adapters'), // Redirect the correct adapter link
    },
    {
      path: '/on-premises/:productName/:version?/index.html?',
      element: <ProductLandingPage selectedCapability={selectedCapability} deployment={'on-premises'}/>,
      loader: async ({ params }) => {
        const deployment = 'on-premises';
        const { productName, version } = params;
        const fullPath = `/${deployment}/${productName}`;

        const validPaths = (Object.keys(productJSON).map(productId => productJSON[productId].baseURL))
        const productCode = (Object.keys(productJSON).filter(productId => productJSON[productId].baseURL === fullPath))[0];

        if(!validPaths.includes(fullPath)) {
          return redirect('/404');
        }
        else if(productCode && version){
          const versions = Object.keys(productJSON[productCode].versions);
          if(version && !versions.includes(version)){
            return redirect(`${fullPath}/${versions[0]}`);
          }
        }
        
        return null;

      },
    },
    {
      path: '/saas/:productName/:version?/index.html?',
      element: <ProductLandingPage selectedCapability={selectedCapability} deployment='saas'/>,
      loader: async ({ params }) => {

        const deployment = 'saas';
        const { productName, version } = params;
        const fullPath = `/${deployment}/${productName}`;
        const validPaths = (Object.keys(productJSON).map(productId => productJSON[productId].baseURL))
        const productCode = (Object.keys(productJSON).filter(productId => productJSON[productId].baseURL === fullPath))[0];

        if(!validPaths.includes(fullPath)) {
          return redirect('/404');
        }
        else if(productCode && version){
          const versions = Object.keys(productJSON[productCode].versions);
          if(version && !versions.includes(version)){
            return redirect(`${fullPath}/${versions[0]}`);
          }
        }
        
        return null;

      },
    },
    {
      path: '/404', 
      element: <NotFoundPage />,
    },
    // {
    //   path: '/:productName/*',
    //   element: <NotFoundPage />,
    //   loader: async ({ params }) => {

    //     const { productName } = params;
    //     const saas_mapping = {
    //         'integration':'/saas/webmethods-integration',
    //         'b2b':'/saas/webmethods-b2b',
    //         'b2bapp':'/saas/webmethods-b2bapp',
    //         'cloudcontainer':'/saas/webmethods-cloud-container',
    //         'mft':'/saas/webmethods-managed-file-transfer',
    //         'embed':'/saas/webmethods-embed',
    //         'e2e':'/saas/webmethods-end-to-end-monitoring',
    //         'softwareagcloud':'/saas/webmethods-ipaas',
    //         'apigateway':'/saas/webmethods-api-gateway',
    //         'developerportal':'/saas/webmethods-developer-portal',
    //     }

    //     if(productName in saas_mapping) {
    //       return redirect(saas_mapping[productName]);
    //     }

    //     return null;
    //   },
    // },
    {
      path: '*',
      element: <NotFoundPage />,
    },
  ]);

  return (
    <RouterProvider router={router}/>
  );
}

export default App;
