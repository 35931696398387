import React from "react";

import './DocumentCategoryOption.css';

const DropdownCategoryOption = (props) => {

    const optionClickHandler = () => {
        window.location.href = `#${props.hashKey}`;
        props.setExpanded(!props.expanded);
    }
    return(
        <li className={["list-box__menu-item", props.selected ? 'list-box__menu-item--highlighted' : ''].join(' ')}
            onClick={optionClickHandler}
        >
            <div className="list-box__menu-item__option">
                {props.title}
            </div>
        </li>
    )
}

export default DropdownCategoryOption;