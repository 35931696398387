import React from "react";

import './SearchCheckBox.css';

const SearchCheckBox = (props) => {

    return(
        <div className="checkbox-wrapper">
            <input 
                className="checkbox"
                type="checkbox" 
                id={`checkbox-${props.id}`}
                value={props.value}
                checked={props.isChecked}
                onChange={() => {props.onChangeHandler(props.key_id)}}
                ref={props.checkboxRef}
            ></input>
            <label htmlFor={`checkbox-${props.id}`} className="checkbox-label">
                <span className="checkbox-label-text">{props.label}</span>
            </label>
        </div>
    )
}

export default SearchCheckBox;