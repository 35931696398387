import React from "react";
import './TopicCard.css';
import DOMPurify from 'dompurify';
import parse from 'html-react-parser';

// import productJSON from '../../Data/products.json';
// import categoriesJSON from '../../Data/topic-categories.json';

const TopicCard = ({ topicObj, parentProduct }) => {

    // const getCategories = () => {
    //    // Description: Function for listing document categories
    //     const categories = [];
    //     const content = parentProduct in productJSON ? productJSON[parentProduct]['content'] : {};

    //     Object.keys(content).forEach(categoryId => {
    //         const topicList = content[categoryId];
    //         if(topicList.includes(topicObj.id)){
    //             if(!categoriesJSON[categoryId].key.startsWith('all')){
    //                 categories.push(
    //                     <div 
    //                         key={`topic-category-${categoriesJSON[categoryId].key}`}
    //                         className="topic-card-tags-item"
    //                     >
    //                         {categoriesJSON[categoryId].title}
    //                     </div>
    //                 )
    //             }
    //         }
    //     });

    //     return categories;
    // };

    // const sanitizedTitle = DOMPurify.sanitize(topicObj.title);
    const sanitizedTitle = DOMPurify.sanitize(topicObj.title);

    return (
        <a 
            href={topicObj.link} 
            target={topicObj.link.startsWith('#') ? "_self" : "_blank" }
            rel={topicObj.link.startsWith('#') ? undefined : "noopener noreferrer"}
            className="topic-card-link" 
            title={`Explore ${parse(sanitizedTitle)}`}
        >
            <div className="topic-card" tabIndex="1" id="1">
                <h4 className="topic-card-title">{parse(sanitizedTitle)}</h4>
                <div className="topic-card-footer">
                    {
                        topicObj.link && topicObj.link.startsWith('http') ?
                        <div className="topic-card-external-link">
                            <span>
                                <svg focusable="false" preserveAspectRatio="xMidYMid meet" fill="currentColor" width="20" height="20" viewBox="0 0 32 32" aria-hidden="true" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M26,28H6a2.0027,2.0027,0,0,1-2-2V6A2.0027,2.0027,0,0,1,6,4H16V6H6V26H26V16h2V26A2.0027,2.0027,0,0,1,26,28Z"/>
                                    <path d="M20 2L20 4 26.586 4 18 12.586 19.414 14 28 5.414 28 12 30 12 30 2 20 2z"/>
                                </svg>
                            </span>
                        </div>:
                        <div className="topic-card-tags">
                            {
                                topicObj.tags.map(tagName => <div key={`topic-tag-${tagName}`} className="topic-card-tags-item">{tagName}</div>)
                            }
                        </div>
                    }
                </div>
            </div>
        </a>
    )
}

export default TopicCard;
