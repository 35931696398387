import React, { useEffect, useRef } from "react";
import { useLocation } from 'react-router-dom';

import './SearchBox.css';

const SearchBox = (props) => {

    const searchboxRef = useRef();

    const location = useLocation();
    
    const search_componentConfig = {
        div: "gcse-searchbox",
        tag: "searchbox",
        gname: "ibm-webmethods-doc-search"
    }

    const searchresults_componentConfig = {
        div: "search-results-placeholder",
        tag: "searchresults",
        gname: "ibm-webmethods-doc-search"
    }

    const gcsInitialCallback = function () {
        if (document.readyState === 'complete') {
            // Document is ready when Search Element is initialized.
            window.google.search.cse.element.render(search_componentConfig, searchresults_componentConfig);
        } else {
            // Document is not ready yet, when Search Element is initialized.
            window.google.setOnLoadCallback(function () {
                window.google.search.cse.element.render(search_componentConfig, searchresults_componentConfig);
            }, true);
        }
    };

    const gcsWebSearchStartingCallback = (gname, query) => {
        let origin = window.location.origin;
        if (query) {
            setTimeout(() => {
                window.open(`${origin}/search?q=${query}`, '_blank').focus();
            }, 100);
        }
        return '';
    };

    // Function to change the placeholder after search box is initialized
    const changePlaceholder = () => {
        const searchInput = document.querySelector('#gsc-i-id1');
        if (searchInput) {
        searchInput.setAttribute('placeholder', 'Search'); 
        }
    };

    const gscElementObserverCallback = async () => {
        document.querySelectorAll('#___gcse_0 + #___gcse_0').forEach(x => x.remove());
    }

    useEffect(() => {
        // Load Google Custom Search Engine script
        const script = document.createElement("script");
        script.src = "https://cse.google.com/cse.js?cx=645f3b5256f74472b";
        script.async = true;
        document.body.appendChild(script); 

        // Define the callback function
        window.__gcse || (window.__gcse = {});
        window.__gcse = {
            parsetags: 'explicit',
            initializationCallback: gcsInitialCallback
        }; 

        window.__gcse.searchCallbacks = {
            web: {
                starting: gcsWebSearchStartingCallback,
            },
        };

        window.addEventListener('load',() => {
            changePlaceholder();
        })

        // Wait for the Google CSE script to load, then modify the placeholder
        script.onload = () => {
            changePlaceholder();
        };

        changePlaceholder();
        setTimeout(changePlaceholder,150);

        const observer = new MutationObserver(gscElementObserverCallback);
        const targetNode = document.querySelector('#gcse-searchbox');
        const config = { childList: true, subtree: false };
        observer.observe(targetNode, config);

        return () => {
            document.body.removeChild(script);
        };
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        // This function will run every time the URL changes
        changePlaceholder(location.pathname);
      }, [location]);

    return (
    <div style={{'width':'100%'}}>
        {/* Search Box */}
        <div ref={searchboxRef} id="gcse-searchbox"></div>
        {/* Search Results */}
        <div ref={searchboxRef} id="search-results-placeholder"></div>
    </div>
    );
};

export default SearchBox;
