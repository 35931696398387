import React,{useState, useEffect, useRef} from "react";
import { cloneDeep } from "lodash";

import Banner from "../../Components/Banner/Banner";
import Footer from "../../Components/Footer/Footer";
import Feedback from "../../Components/Feedback/Feedback";

import ProductCard from "../../Components/ProductCard/ProductCard";
import PidsDropdown from "../../Components/PidsDropdown/PidsDropdown";
import TabGroup from "../../Components/TabGroup/TabGroup";

import capabilityJSON from '../../Data/capabilities.json';
import tocJSON from '../../Data/toc.json';
import productJSON from '../../Data/products.json';

import './LandingPage.css';

const LandingPage = ({selectedCapability, setSelectedCapability}) => {

    // Data state variables
    const ALL_PRODUCTS_ID = 'all';
    // eslint-disable-next-line no-unused-vars
    const [capabilityData, setCapabilityData] = useState(capabilityJSON);
    const [productData, setProductData] = useState(productJSON);

    // Filter state variables
    
    const [searchValue, setSearchValue] = useState('');
    const [altSearchCount, setAltSearchCount] = useState(0);

    // eslint-disable-next-line no-unused-vars
    const [tabList, setTabList] = useState({
        'On-premises': {'title': 'Software', 'value': true, 'count':0},
        'SaaS':{'title': 'SaaS', 'value': false, 'count':0},
    })

    // Pointers to DOM
    const productsSection = useRef(null);

    // URL State variables
    const [urlHash, setUrlHash] = useState('');

    function escapeRegex(string) {
        return string.replace(/[.*+?^${}()|[\]\\]/g, '\\$&');
    }
    
    useEffect(() => {
        const selectedTabValid = Object.keys(tabList).filter(tabKey => tabList[tabKey].value && tabList[tabKey].count)
        const altTab = Object.keys(tabList).filter(tabKey => tabKey !== selectedTabValid[0]).length === 1 ? Object.keys(tabList).filter(tabKey => tabKey !== selectedTabValid[0])[0] : '';
        const fallbackTab = Object.keys(tabList).filter(tabKey => tabList[tabKey].count)[0];
        const fallbackAltTab = Object.keys(tabList).filter(tabKey => tabList[tabKey].count)[1];
        const filteredProuctList = {};
        let altCount = 0;
        
        Object.keys(productJSON).filter(productKey => productJSON[productKey]["product_card_active"] === 'Y').forEach(productKey => {
            let productVal = productJSON[productKey];
            let tocValid = true;
            let searchValid = true;
            let tabValid = selectedTabValid.length ? tabList[productVal.deployment].value : productVal.deployment === fallbackTab;
            let altTabValid = selectedTabValid.length ? productVal.deployment === altTab : productVal.deployment === fallbackAltTab;
            
            // Checking if the product is within the selected capability
            if(selectedCapability !== '' && !(tocJSON[selectedCapability].capability_mapping.filter(cid => productVal.capabilityId.includes(cid)).length) ){
                tocValid = false;
            }

            // Checking if the search term is present in the product title
            searchValue.split(' ').forEach(searchTerm => {
                const escapedSearchTerm = escapeRegex(searchTerm);
                if(!Boolean(productVal["search_string"].match(new RegExp(escapedSearchTerm,'ig')))){
                    searchValid = searchValid && false;
                }
            })

            if(tocValid && searchValid){
                if(tabValid){
                    filteredProuctList[productKey] = productVal;
                }
                else if(altTabValid){
                    altCount += 1;
                }
            }
        });

        setAltSearchCount(altCount);
        setProductData(cloneDeep(filteredProuctList));

    },[selectedCapability,searchValue,tabList])

    useEffect(() => {
        // Update tab count
        const updatedTabList = {...tabList};

        Object.keys(tabList).forEach(tabkey =>{
            const prods = Object.keys(productJSON).filter(prodkey => {
                const capabilityValid = selectedCapability === '' || selectedCapability === ALL_PRODUCTS_ID || tocJSON[selectedCapability]['capability_mapping'].filter(cid => productJSON[prodkey].capabilityId.includes(cid)).length;
                const deploymentValid  = productJSON[prodkey].deployment === tabkey;
                return capabilityValid && deploymentValid;
            });

            updatedTabList[tabkey].count = prods.length;
            // console.log(tabkey,'|', selectedCapability,'|', prods.length);
        })

        if(urlHash && urlHash.endsWith('-saas') && updatedTabList['SaaS'].count){
            updatedTabList['On-premises'].value = false;
            updatedTabList['SaaS'].value = true;
        }
        else{
            updatedTabList['On-premises'].value = true;
            updatedTabList['SaaS'].value = false;
        }

        setTabList({...updatedTabList});
    // eslint-disable-next-line react-hooks/exhaustive-deps
    },[selectedCapability])

    // useEffect runs whenever 'urlHash' is changed
    // 'urlHash' changes when the page first loads, when toc is clicked & capability card is clicked
    // If the urlHash is a valid capability key, 
    // function is responsible for scorlling to the correct position of the page 
    // and updating the selected capapbility
    useEffect(() => {
        if(urlHash){
            let cleanedHash = urlHash.split('-saas')[0];
            let tocId = Object.keys(tocJSON).find(idx => tocJSON[idx].key === cleanedHash);
            if(tocId || cleanedHash === ALL_PRODUCTS_ID){
                // productsSection.current.scrollIntoView({ behavior: "smooth"});
                window.scrollTo({ top: 330, left: 0, behavior: "smooth" })
                setSelectedCapability(tocId ? tocId : '');

                if(urlHash.endsWith('-saas') && tabList['SaaS'].count){
                    const newTabList = cloneDeep(tabList);
                    newTabList['On-premises'].value = false;
                    newTabList['SaaS'].value = true;

                    setTabList(cloneDeep(newTabList));
                }
            }
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    },[urlHash])

    const prodPos = (toc_id, prod_id) => {

        if(toc_id === null){
            const productCapability = productJSON[prod_id].capabilityId;
            toc_id = Object.keys(tocJSON).filter(tocId => {
                return productCapability.some(cid => tocJSON[tocId].capability_mapping.includes(cid));
            }).sort((a,b) => tocJSON[a].toc_position - tocJSON[a].toc_position)[0];
        }

        const parentCapability = tocJSON[toc_id]["capability_mapping"].filter(cid => productJSON[prod_id].capabilityId.includes(cid))[0];
        if(parentCapability){
            return Number(productJSON[prod_id].position[parentCapability])
        }
        else{
            return 0;
        }
    }

    const getTocPos = (prod_id) => {
        const productCapability = productJSON[prod_id].capabilityId;
        const tocId = Object.keys(tocJSON).filter(tocId => {
            return productCapability.some(cid => tocJSON[tocId].capability_mapping.includes(cid));
        }).sort((a,b) => tocJSON[a].toc_position - tocJSON[a].toc_position)[0];

        return tocJSON[tocId].toc_position;
    }

    // useEffect runs only once on component's first load
    useEffect(() => {
        window.addEventListener('hashchange',() => {
            const newHash = window.location.hash.split('#')[1];
            setUrlHash(newHash);
        })

        const newHash = window.location.hash.split('#')[1];
        if(newHash){
            setUrlHash(newHash);
        }
    },[])

    // Element Generation Functions
    const generateProductCards = () => {
        const productCardEls = [];

        if(selectedCapability !== '' && selectedCapability !== ALL_PRODUCTS_ID){
            // Explicity sorting based on position and title
            Object.keys(productData)
                .filter(a => productData[a]['product_card_active'] === 'Y')
                .sort((a, b) => prodPos(selectedCapability,a) - prodPos(selectedCapability,b) || productData[a].title.localeCompare(productData[b].title))
                .forEach((prodKey) => {
                const id = prodKey;
                const pval = productData[prodKey];

                productCardEls.push(
                    <ProductCard 
                        key={id} 
                        product={pval} 
                        capability={pval.capabilityId} 
                    />)
            })
        }
        else{
            // Ordering only alphabetically by title when no pid is selected
            Object.keys(productData)
            .filter(a => productData[a]['product_card_active'] === 'Y')
            .sort((a, b) => getTocPos(a) - getTocPos(b) || prodPos(null,a) - prodPos(null, b) ||  productData[a].title.localeCompare(productData[b].title)).forEach((prodKey) => {
                getTocPos(prodKey);
                const id = prodKey;
                const pval = productData[prodKey];
                productCardEls.push(<ProductCard key={id} product={pval} capability={pval.capabilityId} />)
            })
        }
        
        if(productCardEls.length === 0){
            productCardEls.push(<span key="no-products" id="no-products-found-text">No products found. Change the filters to view more products</span>)
        }

        return productCardEls;
    }

    const generateTocItems = () => {
        const tocEls = [];
        const tocKeys = Object.keys(tocJSON).sort();

        tocEls.push(<span 
            className={["capabilities-toc-item",selectedCapability === '' ? 'active':''].join(' ')} 
            id="all-toc-item" 
            key="all-toc-item"
            category-id="all"
            onClick={() => {window.location.href = `#all`}}
        >
            All Categories
        </span>)

        tocKeys.forEach((id,idx) => {
            const cval = tocJSON[id];
            tocEls.push(
                <span
                key={id}
                className={["capabilities-toc-item",selectedCapability === String(id) ? 'active':''].join(' ')}
                id={`${cval.title.replace(/\s+/g, '-').toLowerCase()}-toc-item`}
                category-id={id}
                onClick={() => {window.location.href = `#${cval.key}`}}
                >
                    {cval.title}
                </span>
            )
        });

        return tocEls;
    }

    // Event Listeners
    const tabClickHandler = (clickedTabKey) => {
        if(!clickedTabKey) return;

        const updatedTabList = cloneDeep(tabList);

        Object.keys(updatedTabList).forEach(tabKey => {
            updatedTabList[tabKey].value = false;
        });

        updatedTabList[clickedTabKey].value = true;
        setTabList(cloneDeep(updatedTabList));

        if(clickedTabKey === 'SaaS'){
            if(selectedCapability){
                window.location.hash = `#${tocJSON[selectedCapability].key}-saas`;
            }
            else{
                window.location.hash = `#all-saas`;
            }
        }
        else{
            if(selectedCapability){
                window.location.hash = `#${tocJSON[selectedCapability].key}`;
            }
            else{
                window.location.hash = `#all`;
            }
        }
    }

    return (
        <React.Fragment>
            <Banner />
            <section className="main-content">
                <section ref={productsSection} id="products-section">
                    <div className="capabilities-toc">
                        {[...generateTocItems()]}
                    </div>
                    <div className="products-container">
                        <div className='flex-wrapper'>
                            <div className="products-container-header">
                                <TabGroup tabList={tabList} tabClickHandler={tabClickHandler}/>
                                <h2>
                                    {
                                        selectedCapability === '' ? 'All Categories'
                                        : tocJSON[String(selectedCapability)]['display_name_long']
                                    }
                                </h2>
                                {/* <p>{
                                    selectedCapability === '' ? 'Learn about the full range of webMethods products and features to make the most of them.'
                                    : tocJSON[String(selectedCapability)].description
                                }</p> */}
                                <div className="capability-dropdown-wrapper">
                                    <PidsDropdown 
                                        labelText="Choose Capability" 
                                        selectedCapability={selectedCapability} 
                                        ALL_PRODUCTS_ID={ALL_PRODUCTS_ID}
                                    />
                                </div>
                                <div className="inpage-searchbox">
                                    <i>
                                        <svg width="13" height="12" viewBox="0 0 13 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M7.33496 12H5.33496C5.06974 12 4.81539 11.8946 4.62785 11.7071C4.44032 11.5196 4.33496 11.2652 4.33496 11V7.205L0.629961 3.5C0.442167 3.31332 0.336077 3.05979 0.334961 2.795V1C0.334961 0.734784 0.440318 0.48043 0.627854 0.292893C0.81539 0.105357 1.06974 0 1.33496 0H11.335C11.6002 0 11.8545 0.105357 12.0421 0.292893C12.2296 0.48043 12.335 0.734784 12.335 1V2.795C12.3338 3.05979 12.2278 3.31332 12.04 3.5L8.33496 7.205V11C8.33496 11.2652 8.2296 11.5196 8.04207 11.7071C7.85453 11.8946 7.60018 12 7.33496 12ZM1.33496 1V2.795L5.33496 6.795V11H7.33496V6.795L11.335 2.795V1H1.33496Z"/>
                                        </svg>
                                    </i>
                                    <input 
                                        id="product-searchbox" 
                                        type="text" 
                                        placeholder="Filter" 
                                        value={searchValue} 
                                        onChange={(event) => setSearchValue(event.target.value)} 
                                        autoComplete="off" 
                                    />

                                    {searchValue && (
                                        <button 
                                        title='Clear'
                                        className="clear-searchbox-button" 
                                        onClick={() => setSearchValue('')} 
                                        aria-label="Clear Search"
                                        >
                                        <svg width="11" height="10" viewBox="0 0 11 10" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <path d="M6.38381 5L10.5 0.883812L9.61619 0L5.5 4.11619L1.38394 0L0.5 0.883812L4.61619 5L0.5 9.11619L1.38394 10L5.5 5.88381L9.61619 10L10.5 9.11619L6.38381 5Z" fill="#525252"/>
                                        </svg>

                                        </button>
                                    )}
                                </div>
                                {
                                    searchValue ?
                                    <span className="product-searchbox-result"><span className="search-count">
                                        {Object.keys(productData).length} results</span> for "{searchValue}"
                                        {   altSearchCount?
                                           <React.Fragment>
                                           <span className='slash'>/</span>
                                           <span className='alt-result' onClick={() => tabClickHandler(Object.keys(tabList).filter(tabKey => !tabList[tabKey].value && tabList[tabKey].count)[0])}>
                                             {altSearchCount} more
                                           </span> 
                                           {' '} found in {tabList[Object.keys(tabList).filter(tabKey => !tabList[tabKey].value && tabList[tabKey].count)[0]].title}
                                         </React.Fragment>
                                         
                                         
                                            :
                                            ''
                                        }
                                    </span>:
                                    ''
                                }
                            </div>
                            <div className="products-list">
                                {generateProductCards()}
                            </div>
                        </div>
                    </div>
                </section>
            </section>
            <Feedback />
            <Footer />
        </React.Fragment>
    )
}

export default LandingPage;
