import React from "react";
import DOMPurify from 'dompurify';
import parse from 'html-react-parser';

import './SearchResult.css';

const SearchResult = props => {
    const sanitizedTitle = DOMPurify.sanitize(props.title);
    const sanitizedDescription = DOMPurify.sanitize(props.description);
    const sanitizedMetadata = DOMPurify.sanitize(props.metadata);

    return(
        <div className="result-item">
            <h5 className="result-item-header">
                <a 
                    href={props.actualLink} 
                    dir="ltr" 
                    target="_blank"
                    rel="noreferrer"
                    data-cturl={props.actualUrl}
                    data-ctorig={props.actualLink}
                >
                    {parse(sanitizedTitle)}
                </a>
            </h5>
            <span className="result-item-metadata">{parse(sanitizedMetadata)}</span>
            <p className="result-item-description">
                {parse(sanitizedDescription)}
            </p>            
        </div>
    )
}

export default SearchResult;