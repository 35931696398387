import React, { useState } from "react";
import DropdownCategoryOption from './DocumentCategoryOption/DocumentCategoryOption';

import './DocumentCategoryDropdown.css';

import topicCategoriesJSON from '../../Data/topic-categories.json';
import productJSON from '../../Data/products.json';

const DocumentCategoryDropdown = (props) => {

    const [expanded, setExpanded] = useState(false);

    const dropdownClickHandler = (event) => {
        event.preventDefault();
        setExpanded(!expanded);
    }
    
    const getSelectedCategoryTitle = () => {
        if(props.selectedCategory === ''){
            if(props.selectedProduct){
                // console.log(props.selectedProduct);
                return topicCategoriesJSON[productJSON[props.selectedProduct]['active_topic_categories'][0]].title;
            }
            else{
                return 'Product Documentation';
            }
        }
        else{
           return topicCategoriesJSON[props.selectedCategory].title
        }
    }

    const generateCategoryOptions = () => {
        const optionsList = [];
        const category = props.selectedCategory ? props.selectedCategory : (props.selectedProduct ? productJSON[props.selectedProduct]['active_topic_categories'][0] : "0");

        if(props.selectedProduct){
            productJSON[props.selectedProduct]['active_topic_categories'].forEach((tid, idx) => {
                const topicObj = topicCategoriesJSON[tid];
                optionsList.push(
                    <DropdownCategoryOption
                        key={tid}
                        hashKey={topicObj.key}
                        title={topicObj.title}
                        selected={tid === category}
                        expanded={expanded}
                        setExpanded={setExpanded}
                    />)
            });
        }

        return [...optionsList];
    }

    return(
        <div className="dropdown-wrapper">
            <label className="dropdown-label" id="downshift-:r5:-label" htmlFor="downshift-:r5:-toggle-button">{ props.labelText }</label>
            <div className={["dropdown-container",expanded ? "list-box--expanded": ""].join(' ')}>
                <button className="list-box_field"
                    type="button"
                    aria-describedby="dropdown-helper" 
                    title="Option 1" 
                    aria-activedescendant="" 
                    aria-controls="downshift-:r5:-menu" 
                    aria-expanded="false" aria-haspopup="listbox" 
                    aria-labelledby="downshif" role="combobox" 
                    tabIndex="0"
                    onClick={dropdownClickHandler}
                >
                    <span className="selected_option_label">{getSelectedCategoryTitle()}</span>
                    <div className="cds--list-box__menu-icon">
                        <svg focusable="false" preserveAspectRatio="xMidYMid meet" fill="currentColor" name="chevron--down" aria-label="Open menu" width="16" height="16" viewBox="0 0 16 16" role="img" xmlns="http://www.w3.org/2000/svg">
                            <path d="M8 11L3 6 3.7 5.3 8 9.6 12.3 5.3 13 6z"></path>
                            <title>Open menu</title>
                        </svg>
                    </div>
                </button>
                <ul className="list-box__menu">
                    {generateCategoryOptions()}
                </ul>
            </div>
        </div>
    )
}

export default DocumentCategoryDropdown;