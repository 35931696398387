import React, { useEffect } from "react";
import BreadcrumbItem from './BreadcrumbItem/BreadcrumbItem';

import './Breadcrumbs.css';

const Breadcrumbs = (props) => {

    // const props.breadcrumbWrapperRef = useRef();

    const generateBreadcrumbItems = () => {
        const items = [];
        props.data.slice(0,-1).forEach(b => {
            if(Object.keys(b).length){
                items.push(
                    <BreadcrumbItem 
                        text={b.text}
                        link={b.link}
                        key={`${b.link}-${b.text}-breadcrumb-item`}
                    />
                )
            }
        });

        props.data.slice(-1).forEach(b => {
            items.push(
                <li 
                    key={'current-breadcrumb'}
                    className="breadcrumb-item current">
                    {b.text}
                </li>
            )
        })

        return items;
    }

    useEffect(() => {
        window.addEventListener('scroll',() => {
            if(window.scrollY > 20){
                if(props.breadcrumbWrapperRef.current !== null){
                    props.breadcrumbWrapperRef.current.setAttribute('style','box-shadow: #16161636 0px 0px 10px 2px;');
                }
            }
            else{
                if(props.breadcrumbWrapperRef.current !== null){
                    // props.breadcrumbWrapperRef.current.style.boxShadow = 'none';
                    props.breadcrumbWrapperRef.current.setAttribute('style','box-shadow: none;');
                }
            }
        })
    // eslint-disable-next-line react-hooks/exhaustive-deps
    },[])

    return(
        <div ref={props.breadcrumbWrapperRef} className="breadcrumb-wrapper sticky-top">
            <ul className="breadcrumb-list">
                {[...generateBreadcrumbItems()]}
            </ul>
        </div>
    )
}

export default Breadcrumbs;