import React, { useState } from "react";
import SearchTagItem from "./SearchTagItem/SearchTagItem";

import './SearchTagList.css';
import productJSON from '../../Data/products.json';

const SearchTagList = props => {

    const [isExpanded, setIsExpanded] = useState(true);

    const generateQuickfilterItems = () => {
        const tags = [];

        props.appliedFilters.forEach(filterkey => {
            const product = productJSON[filterkey]
            tags.push(
                <SearchTagItem
                    key={`filter-tag-${product.product_id}-${product.capabilityId.join('-')}`}
                    data={product}
                    prod_id={product.product_id}
                    searchTagCloseHandler={props.searchTagCloseHandler}
                />
            );
        });

        return tags;
    }

    return(
        <div className={["search-quickfilter-wrapper",isExpanded ? "expanded" : ''].join(' ')}>
            {
                props.appliedFilters.length ? 
                <div className="quickfilter-container-header" onClick={() => setIsExpanded(!isExpanded)}>
                    <span className="quickfilter-container-title">Filters Applied: {isExpanded ? '' : <span className="applied-filter-count">{`+${props.appliedFilters.length} Filters`}</span>}</span>
                    <button className="quickfilter-toggle-btn">
                        <svg width="16" height="16" viewBox="0 -2 16 13" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <rect width="16" height="16" transform="translate(0 -3.5)" fill="white" fillOpacity="0.01" style={{"mixBlendMode":"multiply"}}/>
                        <path d="M8 1.5L13 6.5L12.3 7.2L8 2.9L3.7 7.2L3 6.5L8 1.5Z" fill="#525252"/>
                        </svg>
                    </button>
                </div>
                :''
            }
            {
                isExpanded ? 
                <ul className="quickfilter-list">
                    {[...generateQuickfilterItems()]}
                </ul>
                : ''
            }
        </div>
    )
}

export default SearchTagList;