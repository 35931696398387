import React,{useState, useEffect, useRef} from "react";
import { createPortal } from 'react-dom';

import TopicCard from "../TopicCard/TopicCard";
import VersionDropdown from "../VersionDropdown/VersionDropdown";
import PreviousVersionMessage from '../PreviousVersionMessage/PreviousVersionMessage';
import DocumentCategoryDropdown from '../DocumentCategoryDropdown/DocumentCategoryDropdown';
import InfoMessge from "../InfoMessage/InfoMessage";

import './ProductContent.css';
import productJSON from '../../Data/products.json';
import topicsJSON from '../../Data/topics.json';
import topicCategoriesJSON from '../../Data/topic-categories.json';

const ProductContent = ({ selectedProduct, selectedVersion, contentHeaderRef, leftTocRef }) => {

    // Data state variables
    // eslint-disable-next-line no-unused-vars
    const [categoriesData, setCategoriesData] = useState(topicCategoriesJSON);
    const [topicData, setTopicData] = useState([]);

    // Filter state variables
    const [selectedCategory, setSelectedCategory] = useState('');
    const [searchValue, setSearchValue] = useState('');
    const [urlHash, setUrlHash] = useState('');

    // VersionPicker variables
    const [windowWidth, setWindowWidth] = useState(window.innerWidth);
    const versionDropdownContainerLg = useRef(null);
    const versionDropdownContainerSm = useRef(null);
    const prevVersionContainerLg = useRef(null);
    const prevVersionContainerSm = useRef(null);


    const ALL_DOCUMENTS_KEY = 'all';

    // Function to add a hashchange event listener and
    // update the urHash state on page's first load
    useEffect(() => {
        
        // Logic for handling hash changes
        const handleHashChange = () => {
            const newHash = window.location.hash.split('#')[1];
            setUrlHash(newHash);
        }
        window.addEventListener('hashchange',handleHashChange)

        const newHash = window.location.hash.split('#')[1];
        if(newHash){
            setUrlHash(newHash);
        }

        if(selectedProduct in topicsJSON && selectedVersion){
            setTopicData(productJSON[selectedProduct]['content'][selectedVersion]);
            setSelectedCategory(productJSON[selectedProduct]['active_topic_categories'][0]);
        }

        // Logic for handling window resize
        const handleResize = () => setWindowWidth(window.innerWidth);
        window.addEventListener("resize", handleResize);

        // Cleanup on unmount
        return () => {
            window.removeEventListener("resize", handleResize);
            window.removeEventListener("hashchange", handleHashChange);
        };

    // eslint-disable-next-line react-hooks/exhaustive-deps
    },[]);

    useEffect(() => {
        if(urlHash){
            let categoryId = Object.keys(categoriesData).find(idx => categoriesData[idx].key === urlHash);
            if(categoryId){
                setSelectedCategory(categoryId ? categoryId : productJSON[selectedProduct]['active_topic_categories'][0]);
            }
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    },[urlHash]);

    useEffect(() => {
        const filteredTopicList = [];
        let contentData = {};
        const category = selectedCategory ? selectedCategory : (selectedProduct ? productJSON[selectedProduct]['active_topic_categories'][0] : '');
        
        if(selectedProduct && selectedVersion){
            if(selectedProduct in productJSON && selectedVersion in productJSON[selectedProduct]['content']){
                contentData = productJSON[selectedProduct]['content'][selectedVersion];
            }
        }
        
        const tocSelectedTopics = String(category) in contentData ? contentData[category] : [];
        tocSelectedTopics.forEach(topicKey => {
            let topicObj = topicsJSON[topicKey];
            let searchValid = true;

            searchValue.split(' ').forEach(searchTerm => {
                const escapedSearchTerm = escapeRegex(searchTerm);
                if(!Boolean(topicObj.title.match(new RegExp(escapedSearchTerm,'ig')))){
                    searchValid = searchValid && false;
                }
            })

            if(searchValid){
                filteredTopicList.push(topicKey);
            }
        });

        setTopicData([...filteredTopicList]);

    // eslint-disable-next-line react-hooks/exhaustive-deps
    },[selectedCategory,searchValue, selectedProduct]);

    // Conditionally render the version picker based on screen width
    const renderVersionPicker = () => {
        if(selectedProduct && Object.keys(productJSON[selectedProduct].versions).length > 1){
            if (windowWidth > 771) {
                return createPortal(<VersionDropdown 
                                        selectedProduct={selectedProduct}
                                        versions={productJSON[selectedProduct].versions}
                                        selectedVersion={selectedVersion}
                                    /> , versionDropdownContainerLg.current);
            } else {
                return createPortal(<VersionDropdown 
                                        selectedProduct={selectedProduct}
                                        versions={productJSON[selectedProduct].versions}
                                        selectedVersion={selectedVersion}
                                    /> , versionDropdownContainerSm.current);
            }
        }
        return null;
    };

    // Conditionally render the previous version message based on screen width
    const renderPreviousVersionMessage = () => {
        if(selectedProduct && selectedProduct in productJSON && productJSON[selectedProduct].deployment === 'On-premises'){
            if (windowWidth > 771) {
                return createPortal(<PreviousVersionMessage/>, prevVersionContainerLg.current);
            } else {
                return createPortal(<PreviousVersionMessage/>, prevVersionContainerSm.current);
            }
        }
        return null;
    };

    // Generate the left toc items
    const generateTocItems = () => {
        const tocEls = [];
        const category = selectedCategory ? selectedCategory : (selectedProduct ? productJSON[selectedProduct]['active_topic_categories'][0] : '');

        if(selectedProduct in productJSON){

            productJSON[selectedProduct]['active_topic_categories'].forEach((categoryKey, idx) => {
                const categoryObject = topicCategoriesJSON[categoryKey];
                tocEls.push(<span 
                                className={["topics-toc-item", (category === String(categoryObject.ID) ? 'active':'') ].join(' ')} 
                                id={[categoryObject.ID,'-toc-item'].join('')} 
                                category-id={categoryObject.ID}
                                key={`toc-item-${categoryObject.ID}`}
                                onClick={() => window.location.href = `#${categoryObject.key}`}
                            >
                                {categoryObject.title}
                            </span>)
            })

        }

        return tocEls;
    }

    // Generate topic cards
    const generateTopicCardElts = () => {
        const topicCardList = [];

        topicData.forEach(id => {
            const topicObj = topicsJSON[id];
            topicCardList.push(<TopicCard 
                key={`document-card-${id}`} 
                topicObj={topicObj}
                parentProduct={selectedProduct}
            />);
        });

        if(topicCardList.length === 0){
            topicCardList.push(
                <span key="no-topics-found" style={{'fontStyle':'italic','color':'var(--text-secondary,#525252)'}}>No topics found. Try altering filter selections</span>
            )
        }

        return [...topicCardList];
    }

    // Escape special characters in string
    // Used to escape search input to construct regex
    function escapeRegex(string) {
        return string.replace(/[.*+?^${}()|[\]\\]/g, '\\$&');
    }
    
    return(
        <section id="content-section">
            <div className="topics-toc" ref={leftTocRef}>
                <div className={["version-dropdown-toc-item",selectedProduct && Object.keys(productJSON[selectedProduct].versions).length > 1 ? 'active':''].join(' ')} ref={versionDropdownContainerLg}>
                </div>
                {generateTocItems()}
                <div className="prev-version-toc-item" ref={prevVersionContainerLg}></div>
            </div>
            <div className="topics-container">
                <div className="topics-container-header" ref={contentHeaderRef}>
                    <h2>{selectedCategory ? categoriesData[selectedCategory]['title_long'] : (selectedProduct ? categoriesData[productJSON[selectedProduct]['active_topic_categories'][0]]['title_long'] : 'All Documentation')}</h2>
                    <div className="prev-version-container-sm" ref={prevVersionContainerSm}></div>
                    <div className="version-dropdown-container-sm" ref={versionDropdownContainerSm}></div>
                    <div className="document-category-dropdown-container">
                        <DocumentCategoryDropdown 
                            selectedCategory={selectedCategory}
                            setSelectedCategory={setSelectedCategory}
                            ALL_DOCUMENTS_KEY={ALL_DOCUMENTS_KEY}
                            selectedProduct={selectedProduct}
                        />
                    </div>
                    <div className="topic-searchbox-container">
                        <i>
                            <svg width="13" height="12" viewBox="0 0 13 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M7.33496 12H5.33496C5.06974 12 4.81539 11.8946 4.62785 11.7071C4.44032 11.5196 4.33496 11.2652 4.33496 11V7.205L0.629961 3.5C0.442167 3.31332 0.336077 3.05979 0.334961 2.795V1C0.334961 0.734784 0.440318 0.48043 0.627854 0.292893C0.81539 0.105357 1.06974 0 1.33496 0H11.335C11.6002 0 11.8545 0.105357 12.0421 0.292893C12.2296 0.48043 12.335 0.734784 12.335 1V2.795C12.3338 3.05979 12.2278 3.31332 12.04 3.5L8.33496 7.205V11C8.33496 11.2652 8.2296 11.5196 8.04207 11.7071C7.85453 11.8946 7.60018 12 7.33496 12ZM1.33496 1V2.795L5.33496 6.795V11H7.33496V6.795L11.335 2.795V1H1.33496Z"/>
                            </svg>
                        </i>
                        <input 
                            id="topic-searchbox" 
                            type="text" 
                            placeholder="Filter"
                            value={searchValue} 
                            onChange={(event) => setSearchValue(event.target.value)}
                            autoComplete="off"
                        />

                        {searchValue && (
                            <button 
                                title='Clear'
                                className="clear-searchbox-button" 
                                onClick={() => setSearchValue('')} 
                                aria-label="Clear Search"
                            >
                                <svg width="11" height="10" viewBox="0 0 11 10" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M6.38381 5L10.5 0.883812L9.61619 0L5.5 4.11619L1.38394 0L0.5 0.883812L4.61619 5L0.5 9.11619L1.38394 10L5.5 5.88381L9.61619 10L10.5 9.11619L6.38381 5Z" fill="#525252"/>
                                </svg>
                            </button>
                        )}
                    </div>
                    <span className="topic-searchbox-result">
                    {
                        searchValue ?
                        <React.Fragment>
                            <span className="search-count">
                                {Object.keys(topicData).length} results
                            </span> 
                            &nbsp;for "{searchValue}"
                        </React.Fragment>
                       : ''
                    }
                    </span>
                </div>

                {/* Helper Text for Adapter for Oracle Applications - PTS */}
                {
                    selectedProduct === 'WOA'
                    && String(selectedCategory) === '2'
                    && (
                        <InfoMessge
                            icon={true} 
                            text={
                                <React.Fragment>
                                    Click <a href="https://documentation.softwareag.com/webmethods/adapters_estandards/Adapters/Oracle_Apps/oracleapplications.htm" className="link" target="_blank" rel="noopener noreferrer">here</a> for earlier versions.
                                </React.Fragment>
                            }
                        />
                    )
                }


                <div className="topics-list">
                    {generateTopicCardElts()}
                </div>
            </div>
            {renderVersionPicker()}
            {renderPreviousVersionMessage()}
        </section>
    )
}

export default ProductContent;