import React from "react";
import { Link } from "react-router-dom";

import breadcrumbsArrow from '../../../assets/images/breadcrumbs-arrow.svg';

import './BreadcrumbItem.css';

const BreadcrumbItem = (props) => {
    return (
        <li className="breadcrumb-item" style={{'--arrow-icon':`url(${breadcrumbsArrow})`}}><Link to={props.link}>{props.text}</Link></li>
    )
}

export default BreadcrumbItem