import React,{useEffect, useState} from "react";
import $ from 'jquery';

import productJSON from '../../Data/products.json';
import './VersionDropdown.css';

const VersionDropdown = (props) => {

    const [expanded, setExpanded] = useState(false);

    const dropdownClickHandler = (event) =>{
        event.preventDefault();
        setExpanded(!expanded);
    }

    // const cleanVersion = (v) => {
    //     return v.replace(/([0-9]+).([0-9]+).0$/g,'$1.$2');
    // }

    const generateVersionOptions = () => {
        // console.log(props.versions);
        
        const options = [];
        Object.keys(props.versions).forEach(v => {
            options.push(
                <li
                    key={`${props.selectedProduct}-${v}`}
                    className={["list-box__menu-item",v === props.selectedVersion ? "list-box__menu-item--highlighted":""].join(" ")}
                >
                    <div className="list-box__menu-item__option">
                        <a href={props.versions[v]}>
                            Version {props.selectedProduct ? productJSON[props.selectedProduct].version_labels[v] : ''}
                        </a>
                    </div>
                </li>
            )
        });

        return options;
    }

    useEffect(() => {

        const dropdownCloseHandler = (event) => {
            if(!$(event.target).hasClass('list-box_field')){
                setExpanded(false);
            }
        }

        document.addEventListener("click", dropdownCloseHandler);

        return () => {
            document.removeEventListener("click", dropdownCloseHandler);
        }
    },[])

    return(
        <div className="dropdown-wrapper">
            <label className="dropdown-label" id="downshift-:r5:-label" htmlFor="downshift-:r5:-toggle-button">Change Version</label>
            <div className={["dropdown-container",expanded ? "list-box--expanded": ""].join(' ')}>
                <button className="list-box_field"
                    type="button"
                    aria-describedby="dropdown-helper" 
                    title="Option 1" 
                    aria-activedescendant="" 
                    aria-controls="downshift-:r5:-menu" 
                    aria-expanded="false" aria-haspopup="listbox" 
                    aria-labelledby="downshif" role="combobox" 
                    tabIndex="0"
                    onClick={dropdownClickHandler}
                >
                    <span className="selected_option_label">Version {props.selectedVersion}</span>
                    <div className="cds--list-box__menu-icon">
                        <svg focusable="false" preserveAspectRatio="xMidYMid meet" fill="currentColor" name="chevron--down" aria-label="Open menu" width="16" height="16" viewBox="0 0 16 16" role="img" xmlns="http://www.w3.org/2000/svg">
                            <path d="M8 11L3 6 3.7 5.3 8 9.6 12.3 5.3 13 6z"></path>
                            <title>Open menu</title>
                        </svg>
                    </div>
                </button>
                <ul className="list-box__menu">
                    {
                        generateVersionOptions()
                    }
                </ul>
            </div>
        </div>
    )
}

export default VersionDropdown;