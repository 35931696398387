import React, { useEffect, useState, useRef } from "react";

import './SearchCheckboxGroup.css';
import SearchCheckBox from "../SearchCheckBox/SearchCheckBox";
import { cloneDeep } from "lodash";

import productJSON from '../../Data/products.json';
import tocJSON from '../../Data/toc.json';

const SearchCheckboxGroup = props => {

    const totalChildCount = props.childData.length;

    const [expanded,setExpanded] = useState(props.defaultExpand);
    const [checkedChildCount, setCheckedChildCount] = useState(0);

    const parentCheckboxRef = useRef({});

    useEffect(() => {
        setExpanded(props.defaultExpand);
    // eslint-disable-next-line react-hooks/exhaustive-deps
    },[props.searchValue])

    // control parent checkbox indeterminate state
    useEffect(() => {
        if(checkedChildCount === 0 || checkedChildCount === props.childData.length){
            parentCheckboxRef.current.indeterminate = false;
            
            const newCheckedState = checkedChildCount === totalChildCount;
            const newFilterData = cloneDeep(props.filterData);
            
            newFilterData[props.key_id].parentCheckState = newCheckedState;
            props.setFilterData(cloneDeep(newFilterData))
        }
        else{
            parentCheckboxRef.current.indeterminate = true;
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    },[checkedChildCount]);

    useEffect(() => {
        setCheckedChildCount(props.filterData[props.key_id].childData.filter(child => child.childCheckState).length);
    // eslint-disable-next-line react-hooks/exhaustive-deps
    },[props.filterData]);

    // Event Listener Functions
    const accordianClickHandler = () => {
        setExpanded(!expanded);
    }

    const parentStateChangeHandler = (id) => {
        const newFilterData = cloneDeep(props.filterData);
        const newCheckedState = !newFilterData[props.key_id].parentCheckState;

        // toggle parent checkbox selection
        newFilterData[props.key_id].parentCheckState = newCheckedState;

        // set child checkboxes to checked or unchecked depending on the parent
        newFilterData[props.key_id].childData.forEach(child => {
            const childkey = child.id;
            Object.keys(newFilterData).forEach(pid => {
                newFilterData[pid].childData.forEach(c => {
                    if(c.id === childkey){
                        c.childCheckState = newCheckedState;
                    }
                })
            })
        })

        props.setFilterData(cloneDeep(newFilterData));

        // expand container
        setExpanded(true);
    }

    const childStateChangeHandler = (id) => {
        const childStates = cloneDeep(props.filterData[props.key_id].childData);
        const newChildCheckState = !childStates[id].childCheckState;
        childStates[id].childCheckState = newChildCheckState;


        const newFilterData = cloneDeep(props.filterData);
        childStates[id].capabilityId.forEach(pid => {
            const currentChildId = childStates[id].id;
            newFilterData[pid].childData.forEach(child => {
                if(child.id === currentChildId){
                    child.childCheckState = newChildCheckState;
                }
            })
        });

        // console.log(newFilterData);
        props.setFilterData(cloneDeep(newFilterData));
    }

    const getPos = (child_id) => {
        const product = props.filterData[props.key_id].childData[child_id]
        const parentCid = Object.keys(productJSON[product.id].position).filter(cid => tocJSON[props.key_id]["capability_mapping"].includes(cid))[0];
        const position = productJSON[product.id].position[parentCid]
        if(parentCid){
            return Number(position);
        }
        else{
            return 999;
        }
    }

    // Element Generation Functions
    const generateChildCheckboxes = () => {
        const childCheckboxes = [];

        Object.keys(props.filterData[props.key_id].childData)
            .sort((a,b) => getPos(a) - getPos(b) || props.filterData[props.key_id].childData[a].label.localeCompare(props.filterData[props.key_id].childData[b]))
            .forEach(child_id => {
            const child = props.filterData[props.key_id].childData[child_id];
            childCheckboxes.push(
                <li 
                    key={`li-${child.id}`} 
                    style={{"display": child.searchValid ? "block" : "none"}}
                >
                    <SearchCheckBox
                        id={child.id}
                        label={child.label}
                        value={child.value}
                        isChecked={props.filterData[props.key_id].childData[child_id].childCheckState}
                        filterData={props.filterData}
                        parentkey_id = {props.key_id}
                        key_id={child_id}
                        onChangeHandler={childStateChangeHandler}
                    />
                </li>
            )
        });

        return childCheckboxes;
    }

    return(
        <div 
            className={["checkbox-group",expanded?'expanded':''].join(' ')}
            style={{"display": props.validChildCheckboxCount ? "block" : "none"}}
        >
            <div className="checkbox-group-title" onClick={accordianClickHandler}>
                <SearchCheckBox 
                    id={props.id}
                    label={props.label}
                    value={props.value}
                    isChecked={props.filterData[props.key_id].parentCheckState}
                    onChangeHandler={parentStateChangeHandler}
                    checkboxRef={parentCheckboxRef}
                />
                <button className="checkbox-group-toggle-btn">
                    <svg width="10" height="6" viewBox="0 0 10 6" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M5 5.99687L0 0.996875L0.7 0.296875L5 4.59688L9.3 0.296875L10 0.996875L5 5.99687Z" fill="#525252"/>
                    </svg>
                </button>
            </div>
            <ul className="checkbox-children">
                {[...generateChildCheckboxes()]}
            </ul>
        </div>
    )
}

export default SearchCheckboxGroup;