import React from "react";

import './TabItem.css';
const TabItem = (props) => {
    return(
        <button 
            onClick={() => props.tabClickHandler(props.id)}
            className={["tabs__nav-item tabs__nav-link",props.selected ? "tabs__nav-item--selected" : ''].join(' ')} 
            aria-controls="ccs-:r3:-tabpanel-0" role="tab" tabIndex="0" 
            type="button"
        >
            <div className="tabs__nav-item-label-wrapper">
                <span className="tabs__nav-item-label" dir="ltr">{props.title}</span>
            </div>
        </button>
    )
}
// tabs__nav-item--selected ariaSelected="true"

export default TabItem;