import React from "react";

const PidsDropdownOption = ({ title , selected, id, setExpanded}) => {

    const optionClickHandler = () => {
        window.location.href = `#${id}`;
        setExpanded(false);
    }

    return(
        <li className={["list-box__menu-item", selected ? 'list-box__menu-item--highlighted' : ''].join(' ')}
            onClick={optionClickHandler}
        >
            <div className="list-box__menu-item__option">
                {title}
            </div>
        </li>
    )
}

export default PidsDropdownOption;