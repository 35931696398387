import React,{ useState, useEffect, useRef } from "react";
import { useParams } from 'react-router-dom'
import $ from 'jquery';

import NavBar from "../../Components/NavBar/NavBar";
import Breadcrumbs from '../../Components/Breadcrumbs/Breadcrumbs';
import AnnouncementBanner from '../../Components/AnnouncementBanner/AnnouncementBanner';

import ProductTitle from "../../Components/ProductTitle/ProductTitle";
import ProductContent from "../../Components/ProductContent/ProductContent";
import Feedback from "../../Components/Feedback/Feedback";
import Footer from "../../Components/Footer/Footer";

import './ProductLandingPage.css';

import productJSON from '../../Data/products.json';
import capabilityJSON from '../../Data/capabilities.json';
import tocJSON from '../../Data/toc.json';
import bannerJSON from '../../Data/banners.json';

const ProductLandingPage = ({selectedCapability, deployment}) => {
    const params = useParams();
    const [selectedProduct, setSelectedProduct] = useState('');
    const [selectedVersion, setSelectedVersion] = useState(params.version);
    const [showBanner, setShowBanner] = useState(false);

    const breadcrumbWrapperRef = useRef(null);
    const contentHeaderRef = useRef(null);
    const leftTocRef = useRef(null);
    const announcementBannerRef = useRef(null);

    const deploymentLabels = {'On-premises':'Software', 'SaaS': 'SaaS'};

    const versionSort = (a, b) => {
        const aParts = a.split('.').map(Number);
        const bParts = b.split('.').map(Number);

        for (let i = 0; i < Math.max(aParts.length, bParts.length); i++) {
            const aPart = aParts[i] || 0; // Default to 0 if undefined
            const bPart = bParts[i] || 0; // Default to 0 if undefined

            if (aPart < bPart) return 1;
            if (aPart > bPart) return -1;
        }

        return 0;
    }

    useEffect(() => {
        
        window.scrollTo(0,0);
        // setTimeout(() => {
        //     window.scrollTo({top:0,behavior:"smooth"});
        // },250);

        const currentURL = `/${deployment}/${params.productName}`;
        const productCodeList = Object.keys(productJSON).filter(idx => productJSON[idx].baseURL === currentURL);
        const productCode = productCodeList && productCodeList.length ? productCodeList[0] : null;
        
        if(productCode){

            // setting the selected product
            setSelectedProduct(productCode);

            // setting the selected version
            const versions = Object.keys(productJSON[productCode].versions);
            versions.sort((a,b) => versionSort(a,b));
            
            if(!selectedVersion && versions.length){
                setSelectedVersion(versions[0]);
            }
            else if(!(selectedVersion in versions) && versions.length){
                setSelectedVersion(versions[0]);
            }
            
            // setting the banner state
            let bannerBool = productCode && productCode in bannerJSON;            
            if(bannerBool){
                const closeCount = Number(bannerJSON[productCode].close_count); // get the max close count
                if(closeCount){
                    // if close count is set, check if the number of times the banner was closed is lesser than close count
                    const currentCount = Number(window.localStorage.getItem(`${window.location.pathname}`));
                    if(currentCount < closeCount){
                        window.localStorage.setItem(`${window.location.pathname}`,currentCount);
                    }
                    else{
                        bannerBool = false;
                    }
                }
            }
            setShowBanner(bannerBool);
        }

        // Top offset configuration
        const setTopOffset = () => {
            if(breadcrumbWrapperRef && breadcrumbWrapperRef.current){
                if(contentHeaderRef && contentHeaderRef.current && leftTocRef && leftTocRef.current){
                    const topOffet = breadcrumbWrapperRef.current.querySelector('.breadcrumb-list').getBoundingClientRect().bottom;
                    contentHeaderRef.current.style.top = `${topOffet}px`;
                    leftTocRef.current.style.top = `${topOffet}px`;
                }
            }
        }

        window.addEventListener("resize", setTopOffset);
        window.addEventListener("scroll", setTopOffset);

        // Remove event listeners on unmount
        return () => {
            window.removeEventListener("resize",setTopOffset);
            window.removeEventListener("scroll",setTopOffset);
        }

    // eslint-disable-next-line react-hooks/exhaustive-deps
    },[]);

    const getParentToc = () => {
        // console.log('found:', selectedCapability);
        if(selectedCapability){
            return {"text": tocJSON[selectedCapability].title, "link":`/#${tocJSON[selectedCapability].key}`};
        }
        else if(selectedProduct){
            const parent_tid = Object.keys(tocJSON).filter(tid => tocJSON[tid]["capability_mapping"].filter(cid => productJSON[selectedProduct].capabilityId.includes(cid)).length)[0];
            if(parent_tid){
                return {"text": tocJSON[parent_tid].title, "link":`/#${tocJSON[parent_tid].key}`};
            }
            else{
                return {};
            }
        }

        return {};
    }

    const bannerCloseHandler = () => {
        let bannerBool = selectedProduct && selectedProduct in bannerJSON;    

        if(bannerBool){
            const closeCount = Number(bannerJSON[selectedProduct].close_count); // get the max close count
            const newCount = Number(window.localStorage.getItem(`${window.location.pathname}`)) + 1;
            window.localStorage.setItem(`${window.location.pathname}`,Math.min(closeCount, newCount));
        }

        if(announcementBannerRef.current){
            console.log('hi');
            $(announcementBannerRef.current).slideUp();
        }

        setTimeout(() => {
            setShowBanner(false);
        },300);
    }

    return(
        <React.Fragment>
            <NavBar/>
            {
                showBanner ?
                <AnnouncementBanner 
                    data={bannerJSON[selectedProduct]} 
                    bannerCloseHandler={bannerCloseHandler}
                    announcementBannerRef={announcementBannerRef}
                />
                :''
            }
            {
                selectedProduct && selectedProduct in productJSON ?
                <Breadcrumbs
                    data={[
                        {"text":"Home", "link":"/"},
                        {...getParentToc()},
                        {"text": deploymentLabels[productJSON[selectedProduct].deployment], "link":`/#${capabilityJSON[productJSON[selectedProduct].capabilityId].key}${productJSON[selectedProduct].deployment === 'SaaS' ? '-saas':''}`},
                        {"text":productJSON[selectedProduct].title, "link":productJSON[selectedProduct]['product_card_link']}
                    ]}
                    breadcrumbWrapperRef={breadcrumbWrapperRef}
                />:
                ''
            }
            <section className="product-landing-wrapper">
                <ProductTitle selectedProduct={selectedProduct} selectedVersion={selectedVersion}/>
                <ProductContent 
                    contentHeaderRef={contentHeaderRef} leftTocRef={leftTocRef}
                    selectedProduct={selectedProduct} selectedVersion={selectedVersion} 
                    baseURL={selectedProduct in productJSON ? productJSON[selectedProduct].baseURL : ''}/>
            </section>
            <Feedback />
            <Footer/>
        </React.Fragment>
    )
}

export default ProductLandingPage;